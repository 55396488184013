<template lang="pug">
div
  v-text-field(solo append-icon="search" label="Search" v-model="search")
  v-list(two-line)
    v-list-tile(avatar v-for="unit in filteredInventory" :key="unit['.key']")
      v-list-tile-content
        v-list-tile-title {{unit.vin}}
        v-list-tile-sub-title {{unit.color}} - {{unit.stock}}
      v-list-tile-actions
        v-btn-toggle
          v-btn(:text="unit.location === 'Main'" @click="unit.location = 'Main', changeStatus(unit)")
            span Main
          v-btn(:text="unit.location === 'Overflow'" @click="unit.location = 'Overflow', changeStatus(unit)")
            span Overflow
        // v-chip(small v-if="!model.pictures") Needs Pictures
  // ul.list-group
    li.list-group-item(v-for="unit in filteredInventory" :key="unit['.key']") {{ unit.vin }} - {{ unit.stock }} - {{ unit.color }}
      .btn-group
        button.btn-sm(@click="unit.location = 'Main', changeStatus(unit)" :class="[unit.location === 'Main' ? 'btn-primary' : '', 'btn']") Main Lot
        button.btn-sm(@click="unit.location = 'Overflow', changeStatus(unit)" :class="[unit.location === 'Overflow' ? 'btn-primary' : '', 'btn']") Overflow
        button.btn-sm(@click="unit.location = 'Service', changeStatus(unit)" :class="[unit.location === 'Service' ? 'btn-primary' : '', 'btn']") Service
</template>

<script>
import stickyNav from '@/components/sticky-nav'

export default {
  components: {
    stickyNav
  },
  data () {
    return {
      active: true,
      search: ''
    }
  },
  firebase () {
    return {
      inventory: this.$firebase.database().ref('inventory')
    }
  },
  methods: {
    changeStatus (unit) {
      this.$firebaseRefs.inventory.child(unit['.key']).update({ location: unit.location })
    }
  },
  computed: {
    filteredInventory () {
      var that = this
      var data = this.inventory
      return data.filter(unit => {
        var searchRegex = new RegExp(that.search, 'i')
        return searchRegex.test(unit.stock) || searchRegex.test(unit.vin)
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.list-group-item
  display flex
  justify-content space-between
</style>
